<div class="header bg-primary">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h2 class="fw-500 secondary-font">Comanda {{order.number}}</h2>
        <app-dialog-header-controls [dialogRef]="dialogRef"></app-dialog-header-controls> 
    </div>   
</div> 
<mat-dialog-content>  
    <div class="pt-3"> 


        <div fxLayout="row wrap" class="flex-wrapper"> 
            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">Adresa de Livrare</h2>
            </div>
            <div fxFlex="100" class="px-3 mb-2">
                <div class="divider"></div>
            </div>  
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Nume:</p>
                <p>{{order.personalDetails.name}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Email:</p>
                <p>{{order.personalDetails.email}}</p>
            </div>
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Telefon:</p>
                <p>{{order.personalDetails.phoneNumber}}</p>
            </div>
            <!-- <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Tara:</p>
                <p>Romania</p>
            </div> -->
            <!-- <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Oras:</p>
                <p>{{order.deliveryAddress.city}}</p>
            </div> -->
            <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Detalii Adresa:</p>
                <p>{{order.deliveryAddress.streetAndNo}}<br>{{order.deliveryAddress.flatOrHouseDetails}}<br>{{order.deliveryAddress.city}}</p>
            </div>
            <!-- <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Detalii Adresa 2:</p>
                <p>{{order.deliveryAddress.flatOrHouseDetails}}</p>
            </div> -->
            <!-- <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="25" class="px-3">
                <p class="text-muted mb-1">Address:</p>
                <p>{{deliveryAddress.address}}</p>
            </div>  -->

<!-- 
            <div fxFlex="100" class="px-3 mb-2 mt-3">
                <h2 class="secondary-font">Metoda de Livrare</h2>
            </div>
            <div fxFlex="100" class="px-3">
                <div class="divider"></div>
            </div> 
            <div fxFlex="100" class="px-3 mb-4">
                <p class="py-1">Standard Delivery <span class="text-muted"> $7.99 / Delivery in 5 to 7 business Days</span></p>
            </div>  -->

            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">Metoda de Plata</h2>
            </div>
            <div fxFlex="100" class="px-3">
                <div class="divider"></div>
            </div> 
            <div fxFlex="100" class="px-3 mb-4">
                <!-- <p class="py-1"><span class="text-muted">Card number:</span><span class="mx-1">XXX-XXX-XXX-7224</span></p> -->
                <p class="py-1"><span class="text-muted"></span><span class="mx-1">{{order.status}}</span></p>

            </div> 
            
            <div fxFlex="100" class="px-3 mb-2">
                <h2 class="secondary-font">Comanda</h2>
            </div>
            <div fxFlex="100" class="px-3 mb-5">
                <div class="divider"></div>
                <div class="mat-table theme-table review-order-table">
                    <div class="mat-header-row">
                        <div class="mat-header-cell image">Imagine</div>
                        <div class="mat-header-cell">Nume</div>
                        <div class="mat-header-cell quantity">Cantitate</div>
                        <div class="mat-header-cell price">Pret/Buc</div>      
                        <div class="mat-header-cell total">Total</div> 
                    </div> 
                    <div *ngFor="let item of order.order.items" class="mat-row">
                        <div class="mat-cell image"><img [src]="item.image.small" alt="item"></div>
                        <div class="mat-cell"><a [routerLink]="['/menu', item.id]" class="menu-item-name">{{item.name}}</a></div>
                        <div class="mat-cell quantity">{{item.count}}</div>
                        <div class="mat-cell price">  
                            <span *ngIf="!item.discount" class="primary-color py-3"> 
                                {{item.price}} RON 
                            </span> 
                            <span *ngIf="item.discount" class="primary-color py-3">  
                                {{ (item.price - (item.price * (item.discount / 100)))}} RON
                            </span>  
                        </div>      
                        <div class="mat-cell total">
                            <span *ngIf="!item.discount" class="primary-color py-3"> 
                                {{item.price*item.count}} RON
                            </span> 
                            <span *ngIf="item.discount" class="primary-color py-3">   
                                {{ (item.price - (item.price * (item.discount / 100)))*item.count}} RON
                            </span>  
                        </div> 
                    </div> 
                    <div class="mat-row">
                        <div class="mat-cell image"></div>
                        <div class="mat-cell"></div>
                        <div class="mat-cell price"></div>
                        <div class="mat-cell quantity text-right">
                        <div fxLayout="column" fxLayoutAlign="center end" class="grand-total px-2">
                            <!-- <span>SubTotal:</span>
                            <span class="text-muted">Discount:</span> -->
                            <span>Total de plata:</span>
                        </div>        
                        </div>
                        <div class="mat-cell total">
                            <div fxLayout="column" fxLayoutAlign="center start" class="grand-total">
                                <!-- <span>{{ order.amount | currency : 'USD' : 'symbol' : '1.2-2'}}</span>
                                <span class="text-muted">15%</span> -->
                                <span class="primary-color">{{order.total}}</span>
                            </div>
                        </div> 
                    </div>
                </div>
            </div> 
            
        </div>   

    </div>  
</mat-dialog-content> 