import { Injectable } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpEvent } from '@angular/common/http';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';

import jwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthService {

  private apiRoot = environment.apiRoot;
  private httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

  constructor(private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar) {
  }

  private resetPasswordRequestUrl: string = this.apiRoot + 'request-reset-email/'; // le-am scos un "/" de la incepu de link
  private resetPasswordUrl: string = this.apiRoot + 'reset-password/';

  private setSession(authResult : any) {
    if (authResult.status_code === 200) {
      const token = authResult.token;
      const payload = <JWTPayload> jwtDecode(token);
      const expiresAt = moment.unix(payload.exp);
      localStorage.setItem('token', authResult.token);
      localStorage.setItem('expiresAt', expiresAt.toString());
      localStorage.setItem('isUserLoggedIn', "True");
      this.router.navigate(['/admin']);
    }
  }

  get token(): string {
    return localStorage.getItem('token') ?? "";
  }

  login(loginModel: any, additionalUrlPath?: string) {
    var urlPath = 'login/';
    if (additionalUrlPath !== undefined)
      urlPath = additionalUrlPath + '/login/';
    loginModel.token = '';
    return this.http.post(
      this.apiRoot.concat(urlPath),
      JSON.stringify(loginModel),
      this.httpOptions
    ).pipe(
      tap(response => this.setSession(response)),
      shareReplay(),
    );
  }

  signup(signupModel: any, additionalRoute?: string) {
    return this.http.post(
      this.apiRoot.concat('register/'),
      JSON.stringify(signupModel),
      this.httpOptions
    ).pipe(
      tap(response => this.setSession(response)),
      shareReplay(),
    );
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('isUserLoggedIn');
    this.router.navigate(['home']);
  }
  getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration || '{}');

    return moment(expiresAt);
  }

  isLoggedIn() {
    if (localStorage.getItem("expiresAt")) {
      var expiryDate = Date.parse(localStorage.getItem("expiresAt") || "");
      var currentDate = (new Date()).getTime();
      return expiryDate >= currentDate;
    }
    return false;
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  public passwordResetRequest(body : any) {
    return Observable.create((observer : any) => {
      this.http.post(this.resetPasswordRequestUrl, JSON.stringify(body), {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).subscribe((response) => {
          observer.next(response);
          observer.complete();
        },
        (error) => {
          observer.next(false);
          observer.complete();
        });
    });
  }

  public passwordReset(body : any) {
    console.log(body);
    return Observable.create((observer : any) => {
      this.http.patch(this.resetPasswordUrl, body, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).subscribe((response) => {
          observer.next(response);
          observer.complete();
        },
        (error) => {
          observer.next(false);
          observer.complete();
        });
    });
  }

}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.authService.logout();
      this.router.navigate(['login']); //, { queryParams: {redirectTo: 'profile-page'}});
      return false;
    }
  }
}

interface JWTPayload {
  user_id: number;
  username: string;
  email: string;
  exp: number;
}
