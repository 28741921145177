import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { shareReplay } from 'rxjs/operators';

import { Category } from '../app.models';
import { environment } from 'src/environments/environment';



@Injectable()
export class CommunicationService {
  private apiRoot = environment.apiRoot;

  private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  constructor(private http: HttpClient) {
  }

  addNewCategory(data: any) {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.post(
      this.apiRoot.concat('add-new-category/'),
      data,
      {
        headers: new HttpHeaders({
          'Authorization': token,
        })
      }
    ).pipe(
      shareReplay(),
    );
  }

  editCategory(data: FormData) {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.post(
      this.apiRoot.concat('edit-category/'),
      data,
      {
        headers: new HttpHeaders({
          'Authorization': token,
        })
      }
    ).pipe(
      shareReplay(),
    );
  }

  deleteCategory(category: Category) {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.post(
      this.apiRoot.concat('delete-category/'),
      JSON.stringify(category),
      this.getHttpOptions()
    ).pipe(
      shareReplay(),
    );
  }

  addNewMenuItem(data: FormData) {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.post(
      this.apiRoot.concat('add-new-menu-item/'),
      data,
      {
        headers: new HttpHeaders({
          'Authorization': token,
        })
      }
    ).pipe(
      shareReplay(),
    );
  }

  addNewEcommItem(data: FormData) {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.post(
      this.apiRoot.concat('add-new-ecomm-item/'),
      data,
      {
        headers: new HttpHeaders({
          'Authorization': token,
        })
      }
    ).pipe(
      shareReplay(),
    );
  }

  editMenuItem(data: FormData) {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.post(
      this.apiRoot.concat('edit-menu-item/'),
      data,
      {
        headers: new HttpHeaders({
          'Authorization': token,
        })
      }
    ).pipe(
      shareReplay(),
    );
  }

  editEcommItem(data: FormData) {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.post(
      this.apiRoot.concat('edit-ecomm-item/'),
      data,
      {
        headers: new HttpHeaders({
          'Authorization': token,
        })
      }
    ).pipe(
      shareReplay(),
    );
  }

  deleteMenuItem(item: any) {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.post(
      this.apiRoot.concat('delete-menu-item/'),
      JSON.stringify(item),
      this.getHttpOptions()
    ).pipe(
      shareReplay(),
    );
  }

  deleteEcommItem(item: any) {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.post(
      this.apiRoot.concat('delete-ecomm-item/'),
      JSON.stringify(item),
      this.getHttpOptions()
    ).pipe(
      shareReplay(),
    );
  }

  getAllCategories() {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.get(
      this.apiRoot.concat('get-categories/'),
      this.getHttpOptions()
    ).pipe(
      shareReplay(),
    );
  }

  getAllMenuItems() {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.get(
      this.apiRoot.concat('get-all-menu-items/'),
      this.getHttpOptions()
    ).pipe(
      shareReplay(),
    );
  }

  getAllEcommItems() {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.get(
      this.apiRoot.concat('get-all-ecomm-items/'),
      this.getHttpOptions()
    ).pipe(
      shareReplay(),
    );
  }

  getMenuItemById(id: string) {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.get(
      this.apiRoot.concat('get-menu-item-by-id/'),
      {
        headers: new HttpHeaders({
          'Authorization': token,
          'Content-Type': 'application/json'
        }),
        params: new HttpParams().set("menu_item_id", id)
      }
    ).pipe(
      shareReplay(),
    );
  }

  getEcommItemById(id: string) {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.get(
      this.apiRoot.concat('get-ecomm-item-by-id/'),
      {
        headers: new HttpHeaders({
          'Authorization': token,
          'Content-Type': 'application/json'
        }),
        params: new HttpParams().set("menu_item_id", id)
      }
    ).pipe(
      shareReplay(),
    );
  }

  getHttpOptions() {
    const token = 'Bearer ' + localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': token,
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  getAnalytics() {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.get(
      this.apiRoot.concat('get-analytics/'),
      {
        headers: new HttpHeaders({
          'Authorization': token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      shareReplay(),
    );
  }

  getChartsData() {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.get(
      this.apiRoot.concat('get-charts-data/'),
      {
        headers: new HttpHeaders({
          'Authorization': token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      shareReplay(),
    );
  }

  getLast10Orders() {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.get(
      this.apiRoot.concat('get-last10-orders/'),
      {
        headers: new HttpHeaders({
          'Authorization': token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      shareReplay(),
    );
  }

  getAllOrders() {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.get(
      this.apiRoot.concat('get-all-orders/'),
      {
        headers: new HttpHeaders({
          'Authorization': token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      shareReplay(),
    );
  }

  getAllPayments() {
    const token = 'Bearer ' + localStorage.getItem('token');
    return this.http.get(
      this.apiRoot.concat('get-all-payments/'),
      {
        headers: new HttpHeaders({
          'Authorization': token,
          'Content-Type': 'application/json'
        })
      }
    ).pipe(
      shareReplay(),
    );
  }


}
